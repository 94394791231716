import React from "react"

const ColumnsTwo = ({ children, className }) => {
  let containerClass
  if (className !== undefined && className.includes("gap")) {
    containerClass = "grid grid-cols-1 md:grid-cols-2"
  } else {
    containerClass = "grid grid-cols-1 md:grid-cols-2 gap-8"
  }

  return (
    <div className={`${containerClass} ${className ? className : ""}`}>
      {children}
    </div>
  )
}

export default ColumnsTwo
