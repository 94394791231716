import React from "react"
import { graphql } from "gatsby"
import Layout from "./../components/layouts/Default"
import IndexHeader from "../components/common/IndexHeader"
import Container from "../components/layouts/container/Container"
import BlurbService from "../components/common/BlurbService"
import Cta from "../components/common/Cta"
import SectionTitle from "../components/common/SectionTitle"
import videoCover from "../img/video_cover.jpg"

export default function Page({ data, location }) {
  const svg = {
    tag: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-6 h-6 text-white"
      >
        <path
          fillRule="evenodd"
          d="M17.707 9.293a1 1 0 010 1.414l-7 7a1 1 0 01-1.414 0l-7-7A.997.997 0 012 10V5a3 3 0 013-3h5c.256 0 .512.098.707.293l7 7zM5 6a1 1 0 100-2 1 1 0 000 2z"
          clipRule="evenodd"
        />
      </svg>
    ),
    house: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-6 h-6 text-white"
      >
        <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
      </svg>
    ),
    schema: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-6 h-6 text-white"
      >
        <path
          fillRule="evenodd"
          d="M9.504 1.132a1 1 0 01.992 0l1.75 1a1 1 0 11-.992 1.736L10 3.152l-1.254.716a1 1 0 11-.992-1.736l1.75-1zM5.618 4.504a1 1 0 01-.372 1.364L5.016 6l.23.132a1 1 0 11-.992 1.736L4 7.723V8a1 1 0 01-2 0V6a.996.996 0 01.52-.878l1.734-.99a1 1 0 011.364.372zm8.764 0a1 1 0 011.364-.372l1.733.99A1.002 1.002 0 0118 6v2a1 1 0 11-2 0v-.277l-.254.145a1 1 0 11-.992-1.736l.23-.132-.23-.132a1 1 0 01-.372-1.364zm-7 4a1 1 0 011.364-.372L10 8.848l1.254-.716a1 1 0 11.992 1.736L11 10.58V12a1 1 0 11-2 0v-1.42l-1.246-.712a1 1 0 01-.372-1.364zM3 11a1 1 0 011 1v1.42l1.246.712a1 1 0 11-.992 1.736l-1.75-1A1 1 0 012 14v-2a1 1 0 011-1zm14 0a1 1 0 011 1v2a1 1 0 01-.504.868l-1.75 1a1 1 0 11-.992-1.736L16 13.42V12a1 1 0 011-1zm-9.618 5.504a1 1 0 011.364-.372l.254.145V16a1 1 0 112 0v.277l.254-.145a1 1 0 11.992 1.736l-1.735.992a.995.995 0 01-1.022 0l-1.735-.992a1 1 0 01-.372-1.364z"
          clipRule="evenodd"
        />
      </svg>
    ),
    docs: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-6 h-6 text-white"
      >
        <path d="M9 2a2 2 0 00-2 2v8a2 2 0 002 2h6a2 2 0 002-2V6.414A2 2 0 0016.414 5L14 2.586A2 2 0 0012.586 2H9z" />
        <path d="M3 8a2 2 0 012-2v10h8a2 2 0 01-2 2H5a2 2 0 01-2-2V8z" />
      </svg>
    ),
    speaker: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-6 h-6 text-white"
      >
        <path
          fillRule="evenodd"
          d="M18 3a1 1 0 00-1.447-.894L8.763 6H5a3 3 0 000 6h.28l1.771 5.316A1 1 0 008 18h1a1 1 0 001-1v-4.382l6.553 3.276A1 1 0 0018 15V3z"
          clipRule="evenodd"
        />
      </svg>
    ),
    chat: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-6 h-6 text-white"
      >
        <path
          fillRule="evenodd"
          d="M18 5v8a2 2 0 01-2 2h-5l-5 4v-4H4a2 2 0 01-2-2V5a2 2 0 012-2h12a2 2 0 012 2zM7 8H5v2h2V8zm2 0h2v2H9V8zm6 0h-2v2h2V8z"
          clipRule="evenodd"
        />
      </svg>
    ),
    bulb: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        className="w-6 h-6 text-white"
      >
        <path d="M11 3a1 1 0 10-2 0v1a1 1 0 102 0V3zM15.657 5.757a1 1 0 00-1.414-1.414l-.707.707a1 1 0 001.414 1.414l.707-.707zM18 10a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM5.05 6.464A1 1 0 106.464 5.05l-.707-.707a1 1 0 00-1.414 1.414l.707.707zM5 10a1 1 0 01-1 1H3a1 1 0 110-2h1a1 1 0 011 1zM8 16v-1h4v1a2 2 0 11-4 0zM12 14c.015-.34.208-.646.477-.859a4 4 0 10-4.954 0c.27.213.462.519.476.859h4.002z" />
      </svg>
    ),
  }

  const services = [
    {
      to: "/leistungen/immobilienbewertung/",
      title: "Immobilienbewertung",
      text: "Wir ermitteln ganz einfach den Wert Ihrer Immobilie.",
    },
    {
      to: "/leistungen/expose/",
      title: "Erstellung Exposé",
      text:
        "Die Grundlage für einen erfolgreichen und zeitnahen Verkauf eines Hauses oder einer Wohnung.",
    },
    {
      to: "/leistungen/grundriss/",
      title: "Anfertigung Grundriss",
      text:
        "Einfach schöne und günstige Grundrisse und Visualisierungen Ihrer Immobilie.",
    },
    {
      to: "/leistungen/vermarktung/",
      title: "Vermarktung auf Internetportalen",
      text:
        "Wir vermarkten Ihre Immobilie und verbreiten diese in den gängigen Internetportalen.",
    },
    {
      to: "/leistungen/energieausweis/",
      title: "Erstellung Energieausweis",
      text:
        "Der Energieausweis ist ein Dokument, das Daten zur Energieeffizienz und zu den anfallenden Energiekosten eines Gebäudes liefern soll.",
    },
  ]


  return (
    <Layout
      location={location}
      title="Döbbel Immobilien | Immobilienmakler in Staßfurt OT Förderstedt"
      name="Startseite"
      desc="Ihr Fachmann in Sachen Immobilien Deutschlandweit! Unsere Leistung ist ihr Erfolg."
    >
      <IndexHeader
        titleSmall="Thomas Döbbel"
        title="IMMOBILIEN"
        text="Ihr Fachmann in Sachen Immobilien Deutschlandweit! Unsere Leistung ist ihr Erfolg."
        buttonText="Mehr Infos"
        buttonLink="/leistungen/"
        img={data.header.childImageSharp.fluid}
      />

      <Container className="my-16 md:my-32">
        <SectionTitle
          title="Leistungsübersicht"
          subtitle="Leistungen"
          text="Sie suchen einen Mieter oder einen Käufer? Wir vermitteln gerne und sorgen dafür, dass Sie mit Ihrer Immobilie das beste Ergebnis erzielen. Bei uns bekommen Sie genau die Beratung und Unterstützung, die Sie sich wünschen."
          className="mx-auto text-center "
        />

        <div className="max-w-2xl mx-auto my-16 overflow-hidden shadow-xl rounded-xl">
          <video width="848" height="480" className="w-full h-auto cursor-pointer" controls poster={videoCover}  >
            <source src="https://res.cloudinary.com/lautenschlager/video/upload/v1614627541/kunden/doebbel-immobilien-werbevideo_nex0nu.mp4" type="video/mp4" />
          </video>
        </div>

        <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {services.map((service, i) => {
            let icon
            switch (service.title) {
              case "Immobilienbewertung":
                icon = svg.tag
                break
              case "Erstellung Exposé":
                icon = svg.house
                break
              case "Anfertigung Grundriss":
                icon = svg.schema
                break
              case "Vermarktung auf Internetportalen":
                icon = svg.speaker
                break
              case "Erstellung Energieausweis":
                icon = svg.bulb
                break
              default:
                break
            }

            return (
              <BlurbService
                title={service.title}
                text={service.text}
                svg={icon}
                to={service.to}
              />
            )
          })}
        </div>
      </Container>
      <Container className="my-8 md:my-16">
        <Cta />
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query {
    header: file(relativePath: { eq: "thomas-doebbel.jpg" }) {
      childImageSharp {
        fluid(jpegQuality: 100, maxWidth: 610) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
