import React from "react"
import { Link } from "gatsby"

const Blurb = ({ title, text, svg, to }) => {
  return (
    <div>
      <Link to={to}>
        <div class="pt-6">
          <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8 text-center hover:shadow-xl target:shadow-xl transition-shadow duration-300">
            <div class="-mt-6">
              <div className="">
                <span class="inline-flex items-center justify-center p-3 bg-brand rounded-md shadow-lg">
                  {svg}
                </span>
              </div>
              <h3 class="mt-8 text-lg font-medium tracking-tight">{title}</h3>
              <p class="mt-5 text-base text-gray-900">{text}</p>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Blurb
