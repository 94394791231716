import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import Container from "../layouts/container/Container"
import ColumnsTwo from "../layouts/container/ColumnsTwo"

const IndexHeader = ({
  title,
  titleSmall,
  text,
  buttonText,
  buttonLink,
  img,
}) => {
  return (
    <div className="relative bg-gray-50">
      <Container className="py-8 max-w-7xl md:py-16">
        <ColumnsTwo className="items-center">
          <div>
            <h1 className="text-4xl font-extrabold tracking-tight text-brand sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
              <span className="block text-xl font-bold text-gray-900 sm:text-3xl">
                {titleSmall}
              </span>
              {title}
            </h1>
            <p className="mt-3 text-lg tracking-wider text-gray-600 sm:text-2xl md:mt-5">
              {text}
            </p>
            <div className="mt-10">
              <Link
                to={buttonLink}
                className="inline-flex items-center px-8 py-3 text-base font-medium text-white border border-transparent rounded-md shadow rounded-mdjustify-center bg-brand hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
              >
                {buttonText}
              </Link>
            </div>
          </div>

          <div>
            <Img
              fluid={img}
              className="max-w-md mx-auto rounded shadow-lg"
            />
          </div>
        </ColumnsTwo>
      </Container>
    </div>
  )
}

export default IndexHeader
